import request from '@/utils/request'
import store from '@/store'
export const NavService = {
  fetchRoutes,
}

function fetchRoutes(){
  let role = store.state.user.current.role
  let current = store.state.user.data
  console.log('this role: '+role)
  return request({
    url:'/menu',
    method:'post',
    data:{
      role:role,
      tahun:current.tahun
    }
  })
}
