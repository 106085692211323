<template>
  <v-app>
    <Snackbar />

    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <dashboard-core-settings />

    <dashboard-core-footer />
  </v-app>
</template>

<script>
  import Snackbar from '@/components/common/Snackbar'
  import store from '@/store'
  export default {
    name:"Default",
    components: {
      Snackbar,
      DashboardCoreAppBar: () => import('@/components/core/AppBar'),
      DashboardCoreDrawer: () => import('@/components/core/Drawer'),
      DashboardCoreSettings: () => import('@/components/core/Settings'),
      DashboardCoreView: () => import('@/components/core/View'),
      DashboardCoreFooter: () => import('@/components/core/Footer')
    },
    created(){
      // set theme from default
      this.$vuetify.theme.dark = store.state.settings.darkMode
      let primary = store.state.settings.primaryColor
      if (primary){
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = primary
      }
    }
  }
</script>
