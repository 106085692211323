<template lang="html">
  <v-dialog
      v-model="dialog"
      persistent
      :width="widthDialog"
      min-width="350"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="properties.color"
        dark
        v-bind="attrs"
        v-on="on"
        :class="properties.class"
        :small="properties.small"
        :x-small="properties.xSmall"
      >
        <v-icon v-if="properties.icon" :small="properties.small" :x-small="properties.xSmall" left >{{properties.icon}}</v-icon>
        {{properties.name}}
      </v-btn>
    </template>
    <v-card :color="cProperties.color">
      <v-card-title class="headline">
        {{title}}
      </v-card-title>
      <v-card-text v-html="content"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="disagree()"
        >
          Tidak
        </v-btn>
        <v-btn
          color="red darken-1"
          @click="agree()"
          dark
        >
          Ya
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:{
    title:String,
    content:String,
    data:Object,
    btnProps:{
      name:String,
      color:{
        type:String,
        default:'primary'
      }
    },
    cardProps:Object,
    width:String
  },
  data(){
    return{
      dialog:false,
      default:{
        color:'primary',
        class:'mx-2',
      },
      cDefault:{
        color:'white'
      }
    }
  },
  computed:{
    properties(){
      return {...this.default, ...this.btnProps}
    },
    cProperties(){
      return {...this.cDefault,...this.cardProps}
    },
    widthDialog(){
      return this.width
    }
  },
  methods:{
    agree(){
      this.dialog = false
      this.$emit('on-agree', this.data)
    },
    disagree(){
      this.dialog = false
    }
  }

}
</script>

<style lang="css" scoped>
</style>
