import { render, staticRenderFns } from "./ConfirmBtn.vue?vue&type=template&id=7aabd1d7&scoped=true&lang=html&"
import script from "./ConfirmBtn.vue?vue&type=script&lang=js&"
export * from "./ConfirmBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aabd1d7",
  null
  
)

export default component.exports