/*
  dummy server untuk aplikasi teman kerja
  dengan cara menghasilkan panggilan sesuai dengan server asli. Digunakan untuk
  testing dan development
  !!! JANGAN DIGUNAKAN UNTUK PRODUKSI !!!
*/
import {
  Server,
  Model
} from 'miragejs'
import staff from './sample.menu.staff.json'
import admin from './sample.menu.admin.json'
import catImage from './pic/cat4x3.jpg'
import {NIPGen} from './lib-dummy.js'
import { LoremIpsum } from "lorem-ipsum";

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});

export function makeServer({
  environment = "development"
} = {}) {

  let server = new Server({
    environment,

    models: {
      todo: Model,
    },

    routes() {
      this.namespace = "api"

      this.get("/todos", schema => {
        return schema.todos.all()
      })

      this.post("/auth/signin",(schema, request)=>{
        let attrs= JSON.parse(request.requestBody)
        console.log(attrs)
        if (attrs['username'] == 12345 ){
          return {
            status: "200",
            username: "12345",
            year:'9898',
            id: 2,
            email: "user2@rev.web.id",
            roles: ["staff","direktur"],
            photo: "/photo/cat",
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiaWF0IjoxNTk0ODIxNjg2LCJleHAiOjE1OTQ4MjI1ODZ9.Qf01ybRUl__EHskwOSN2XJHa2yineG2hJPouyMPQZGs",
            tokenRefresh: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiaWF0IjoxNTk0ODIxNjg2LCJleHAiOjE1OTQ5MDgwODZ9.ciRtZ8WP9-rVHfTvLkAl7RTvdLla8hqFex5WfVPOoYI",
          }
        } else {
          return {
            message:"salah username/password"
          }
        }
      })

      this.post("/logout", ()=>{
        return {
          message: "success"
        }
      })

      this.get("/menu/superadmin",()=>{
        return {...admin}
      })

      this.post("/menu",()=>{
        return {...staff}
      })

      this.get("/photo/cat",()=>{
        return catImage
      })

      this.get("/profile-pegawai", () => {
        let seed = "Erin Bradley Esme Reeve Emaan Santiago Jodie Arias Geoffrey Rawlings Felix Christie Frankie Hart Juno Boyle Aman Meyers Jean Burris Wren Simmons Niyah Landry Aston Pike Robyn Hunt Iman Begum Zane Holmes Shae Costa Meredith House Brady Allison Storm Leblanc Hanan Francis Rui Wormald Hadley Liu Rudi Hutchings Ronnie Mayo Billy Parra Devon Rollins Lennie Cisneros Hilda Wallis Aiden Charlton Celyn Cervantes Eman Ayala Jaden Kenny Piper England Alex Fields Kit Ball Brogan Reyes Colby Pollard Kian Reese Lara Deacon  Carson Mcneil  Armani Espinosa  Ty Bloggs  Karam Rodrigues  Leslie Knapp  Teddy Ahmad  Miller Noel   Malik Knight  Jaye Lozano   Lindsay Perkins  Georgie Mercado  Simran Mcmahon  Alfie Guevara  Jaylan Maddox  Mikaela Santana  Joel Cresswell  Elena Rankin  Diya Curtis  Romy Pugh  Mylie Goodwin  Madison Bowen  Joey Delacruz Nel Morales Nikki Wong Dakota Hardin Avi Vaughn Tanya Rivas Remy Rose Alexis Oliver River Conley Marissa Welch Troy Curran Keir Clarke Nur Carlson Tia Christian Etienne Nixon Lachlan Dickerson Amani Brewer Regan Dale Kayan Heaton Kaden Keith Ihsan Roy Mason Flowers Justine Watkins Sammy Petty Chandler Berry Sheikh Hunter Jaskaran Barrett Falak Ellwood Arlo Oneal Callan Lowe Arya Mccarty Rayhan Hale Cade Hook Asa Cuevas Nadia Cooke Parris Hudson Tamar Cooley Amara Byrne Nikita Wilkinson"
        let names = seed.split(" ")
        let pegawai = []
        for (var i = 0; i < 100; i++){
          let tempPegawai = {
            no:i,
            peg_nama:names[Math.floor(Math.random()*names.length)] + " " + names[Math.floor(Math.random()*names.length)],
            peg_nip: NIPGen,
            jabatan: lorem.generateWords(1),
            eselon: lorem.generateWords(1),
            golongan: lorem.generateWords(1),
            job_value: Math.floor(Math.random()*1000) + "\n" + lorem.generateWords(5)
          }
          pegawai.push(tempPegawai)
        }
        return pegawai
      })

    },
  })

  return server
}
