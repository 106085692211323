import {parseBoolean} from '@/utils/lib'

function constructNav(idx, rawNav){
  // copy object properties ke temporary variable
  let tempNav = {...rawNav.find(x=> x.id === idx)}
  let nav = {}

  // restructure
  let icon = tempNav.icon
  if (!icon ){
    icon = 'mdi-alert-circle'
  } else if ( !(icon.match(/^mdi-/)) ){
    icon = ''
  }

  nav.name = tempNav.name
  nav.meta = {icon: icon, title:tempNav.name}
  nav.order = tempNav.order

  if (!parseBoolean(process.env.VUE_APP_IGNORE_HIDDEN)){
    nav.hidden = tempNav.is_hide
  }

  if (tempNav.url == "#"){
    nav.component = ()=> import('@/layout/Default')
    nav.path = ""
  }else {
    nav.path = tempNav.url
    if(!/^\//.test(tempNav.url)) {
      nav.path = '/' + nav.path
    }
    let tempComponent = tempNav.component ? tempNav.component : '/UnderConstruction'
    nav.component = ()=>import('@/views'+tempComponent+'.vue')
  }

  if (tempNav.children){
    let children = tempNav.children

    nav.children=[]
    for (let i =0; i < children.length; i++){
      let constructedChild = constructNav(children[i], rawNav)
      // prevent undefined name
      if (constructedChild.name){
        nav.children.push(constructedChild)
      }
    }
    nav.children.sort((x,y) => x.order*1 - y.order*1)
  }

  return nav
}


/**
 * Buat navigasi berdasarkan server input
 * dan dikonversi agar dipahami oleh vue
 * @param {Array} raw Array Objek untuk pembuatan navigasi
 * @return navigasi yang di pahami oleh vue
 */
export function generateNav(raw){
  console.log(raw)
  let nav = []
  let module = raw.menu
  let root = module.find(x => x.id === '0')

  for (var i=0; i < root.children.length;i++){
    let idx = root.children[i]

    let tempNav = constructNav(idx, module)

    // construct children for root without children
    // necessary for routing purpose
    if(!(tempNav.children) || !(tempNav.children.length > 0)){
      delete tempNav.name
      let tempItem = module.find(x => x.id === idx)
      if (tempItem){
        let tempComponent = tempItem.component ? tempItem.component : '/UnderConstruction'
        tempNav.component = () => import('@/layout/Default.vue')
        tempNav.children=[{
          name: tempItem.name,
          component: () => import('@/views' + tempComponent + ".vue"),
          path:''
        }]
      }
    }
    nav.push(tempNav)
  }

  // sort
  nav.sort((x,y) => x.order*1 - y.order*1)
  console.log(nav)
  return nav
}
