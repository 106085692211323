export default ({
  namespaced:true,
  state:{
    profile:false,
    nip:'',
    loading:false
  },
  mutations:{
    SET_PROFILE(state, {profile,nip}){
      // open profile dialog
      state.profile = profile
      // set nip ( username )
      if (nip) state.nip = nip
    },
    SET_LOADING(state, loading){
      state.loading = loading
    }
  }
})
