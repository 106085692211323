import Vue from 'vue'

const formatCurrency = Vue.filter('formatCurrency', function (value, c, d, t) {
    var n = value
    var c_ = isNaN(c = Math.abs(c)) ? 0 : c
    var d_ = d == undefined ? "," : d
    var t_ = t == undefined ? "." : t
    var s = n < 0 ? "-" : ""
    var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + ""
    var j = (j = i.length) > 3 ? j % 3 : 0
    return s + (j ? i.substr(0, j) + t_ : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t_) + (c_ ? d_ + Math.abs(n - i).toFixed(c).slice(2) : "")
})

export default formatCurrency