import _ from 'lodash'

export default({
  namespaced:true,
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/Rose-Petals.svg'),
    darkMode:false,
    primaryColor:'',
    drawer: null,
    smallMenu:true,
    site:{}
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SMALL_MENU(state, payload){
      state.smallMenu = payload
    },
    SET_DARK(state,val){
      state.darkMode = val
    },
    SET_PRIMARY(state,val){
      state.primaryColor = val
    },
    SET_SITE(state,val){
      state.site = _.clone(val)
      console.log(state.site)
    }
  },
  actions: {

  },
})
