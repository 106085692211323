import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

// using locale
import id from 'vuetify/es5/locale/id'
import en from 'vuetify/es5/locale/en'

Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'id'
    },
  },
})


// const theme = {
//   primary: '#4CAF50',
//   secondary: '#9C27b0',
//   accent: '#9C27b0',
//   info: '#00CAE3',
// }
const theme =  {
      primary: '#004159',
      secondary: '#00bcd4',
      accent: '#ff9800',
      error: '#e91e63',
      info: '#03a9f4',
      success: '#8bc34a',
      reds: colors.red.darken4
}

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: {id, en},
    t: (key, ...params) => i18n.t(key, params),
    current:'id'
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
