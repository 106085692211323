import axios from 'axios'
// import moment from 'moment'
// import router from '@/router'
import store from '@/store'
import {parseBoolean} from '@/utils/lib'
import router from '../router'
import _ from 'lodash'
import DashboardService from '@/services/DashboardService'

// create an axios instansce
var API_URL = ''
if (process.env.VUE_APP_SERVER === 'local'){
  API_URL = ''
}else {
  API_URL = process.env.VUE_APP_API_URL
}

const service = axios.create({
  baseURL: API_URL + '/api/',
  // timeout: 30000,
})

// handle ott sso
var direct_token = null

// fungsi loop untuk otomatis refresh token
function createAxiosResponseInterceptor() {
  const interceptor = service.interceptors.response.use(
    response => response,
    error => {
      // const token = direct_token != null ? direct_token : store.getters['user/token']
      let check = store.state.user
      const token = check.data ? check.data.token : store.getters['user/token']
      direct_token = token

      if(check.data){
        if(check.data.direct){
          router.push('/ssoredirect')
        }
      }

      // console.log('response', error.response)
      // if for stange reason there is no response
      if (!error.response){
        store.commit('snackbar/setSnack',{message:'Response Error CAUSE: '+ error.message+'. Coba beberapa saat lagi.', color:'error'})
        return Promise.reject(error)
      }

      if (
        // Reject promise if it is common error
          ![401,403,400].includes(error.response.status) ||
          error.response.data.status === '50400' ||
          error.response.data.status*1 < 1000
        ){
        store.commit('snackbar/setSnack',{message: error.message+" CAUSE: "+error.response.data.message, color:'error'})
        return Promise.reject(error);
      }

      // if due to strange reason token dissapear
      if (token == null && direct_token == null){
        console.log('TOKEN NULL')
        // push logout
        store.dispatch('user/eject')
        return Promise.reject(error)
      }

      if (isNaN(error.response.data.status * 1)){
        console.log('salah')
        store.commit('snackbar/setSnack',{message: 'Fatal Error: Format JSON salah', color:'error'})
        return Promise.reject(error);
      }

      // console.log(error.response.data.status)

      // if (error.response.data.status === 10403){
      //   store.dispatch('user/logout')
      //   return Promise.reject(error);
      // }

      if ((error.response.data.status*1 === 10400 || error.response.data.status*1 === 20400) && direct_token == null){
        console.log('user ejected')
        // token sudah tidak ada
        // bersihkan data user dan kembali ke login
        store.dispatch('user/eject')
        return Promise.reject(error);
      }

      // selain 401 dianggape error biasa
      if ((error.response.status == 400 && error.response.data.status*1 != 10404) || error.response.status == 403){
        store.commit('snackbar/setSnack',{message: error.message+" CAUSE: "+error.response.data.status+": "+error.response.data.message, color:'error'})
        return Promise.reject(error)
      }
      // if (error.response.data.status == 10401){
      //   store.dispatch('user/logout')
      //   return Promise.reject(error)
      // }

      /*
      * When response code is 401, try to refresh the token.
      * Eject the interceptor so it doesn't loop
      */
      console.log('TRY RETRIEVE REFRESH TOKEN')
      service.interceptors.response.eject(interceptor);
      return service({
        url:'/auth/refreshToken/',
        method:'post',
        data:{
          tokenRefresh: store.getters['user/refreshToken']
        }
      }).then(response => {
        console.log('REFRESH TOKEN SUCCESSFUL')
        store.commit('user/SET_TOKEN', response.data.token )
        error.response.config.headers['x-access-token'] = store.getters['user/token'];
        console.log(error.response.config)
        return service(error.response.config);
      }).catch(error => {
        let check = store.state.user
        if(check.data){
          if(check.data.direct){
            router.push('/ssoredirect')
          }
        }
        console.error('REFRESH TOKEN FAILED', error)
        if (error.response.data.status * 1 > 20000){
          console.error('token error', error.response)
          store.dispatch('user/logout')
          // token dissapear back to login
          // router.push({path:'/login'});
        }
        return Promise.reject(error);
      }).finally(createAxiosResponseInterceptor);
    }
  )
}

if (process.env.VUE_APP_SERVER === 'prod'){
  // generic method each request carries a token
  service.interceptors.request.use(
    config => {
      if(config.data){
        direct_token = config.data.token
      }
      let check = store.state.user
      const token = check.data ? check.data.token : store.getters['user/token']
      if (token || direct_token != null) {
        config.headers['x-access-token'] = direct_token != null ? direct_token : token
      // if (token || direct_token != null || token_ != null) {
      // if (token) {
      //   config.headers['x-access-token'] = token
        // use json as default
        if (!config.headers['Content-Type']){
          config.headers['Content-Type'] = 'application/json'
        }
      }
      return config
    },
    error => {
      console.error('interceptor',error)
      return Promise.reject(error)
    }
  )

  console.log('STATUS: VUE_APP_DISABLE_TOKEN_REFRESH', process.env.VUE_APP_DISABLE_TOKEN_REFRESH )
  console.log('STATUS: VUE_APP_IGNORE_HIDDEN', parseBoolean(process.env.VUE_APP_IGNORE_HIDDEN))
  if (process.env.VUE_APP_DISABLE_TOKEN_REFRESH === 'false'){
    console.log('DISABLED: VUE_APP_DISABLE_TOKEN_REFRESH')
    createAxiosResponseInterceptor()
  } else {
    console.log('ENABLED: VUE_APP_DISABLE_TOKEN_REFRESH')
  }
}

export default service
