import {generateNav} from '@/utils/navGenerator'
import {NavService} from '@/services'
import _ from 'lodash'
import axios from 'axios'
import store from '@/store'

export default ({
  namespaced:true,
  state: {
    routes : [],
    rawRoutes:[],
    isAddRoutes:false,
    loopGuard:0,
    refresh: false,
    flatenRoutes:[]
  },
  mutations: {
    SET_ROUTES(state, val){
      state.routes = _.cloneDeep(val)
    },
    SET_FLAT_ROUTES(state, routes){
      let results = []

      function loopRoutes(items, i){
        if (items[i]){
          if (items[i].children) {
            if (items[i].children.length > 0){
              loopRoutes(items[i].children,0)
            }
          }
          if (items[i].path) {
            results.push(items[i].path)
          }
        }

        if (i < items.length){
          loopRoutes(items,++i)
        }
      }
      loopRoutes(routes, 0)

      state.flatenRoutes = results
    },
    SET_LOADED(state,val){
      state.isAddRoutes = val
    },
    SET_RAW_ROUTES(state,val){
      state.rawRoutes = _.cloneDeep(val)
    },
    RESET_LOOP_GUARD(state){
      state.loopGuard = 0
    },
    LOOPING(state){
      state.loopGuard += 1
    },
    CLEAR_ALL(state){
      state.routes = []
      state.rawRoutes = []
      state.isAddRoutes = false
      state.loopGuard = 0
    },
    REFRESH(state){
      // variable to refresh routes
      state.refresh = true
    }
  },
  actions: {
    setDrawer({commit, state}){
      commit('SET_DRAWER', !state.drawer)
    },
    generateRoutes({commit,state}){
      console.log('calling generate routes')
      return new Promise( (resolve) => {
        let check = store.state.user
        let current = store.state.user.data
        if(check.direct){
          console.log('sso ambil navigasi menu terbaru')
          const config = {
            headers:{
              "x-access-token": check.data.token,
              "Content-Type": "application/json"
            }
          }
          var API_URL = ''
          if (process.env.VUE_APP_SERVER === 'local'){
            API_URL = ''
          }else {
            API_URL = process.env.VUE_APP_API_URL
          }
          axios.post(API_URL+'/api/menu', {'role': check.current.role, 'tahun': current.tahun}, config)
          .then(response => {
            let data = response.data
            state.refresh = false
            let newNav = generateNav(data)
            commit('SET_RAW_ROUTES', data.menu)
            // masukkan pada aplikasi
            commit('SET_ROUTES', newNav)
            commit('SET_FLAT_ROUTES', newNav)
            resolve(newNav)
          })
          .catch(error => {
            if (error.response.data.status == 10404 && error.response.status == 400){
              console.log('Failed logout (10404).')
              store.dispatch('user/logout')
              return Promise.reject(error)
            }
          })
        }else{
          if (state.rawRoutes.length > 0 && !state.refresh ){
            let newNav = generateNav({menu:state.rawRoutes})
            commit('SET_ROUTES', newNav)
            commit('SET_FLAT_ROUTES', newNav)
            resolve(newNav)
          }
          else {
            console.log('ambil navigasi terbaru')
            // ambil navigasi terbaru
            NavService.fetchRoutes().then(response=>{
              // kembalikan refresh ke false
              state.refresh = false
              let newNav = generateNav(response.data)
              commit('SET_RAW_ROUTES', response.data.menu)
              // masukkan pada aplikasi
              commit('SET_ROUTES', newNav)
              commit('SET_FLAT_ROUTES', newNav)
              resolve(newNav)
            })
          }
        }
      })
    },
    reset({commit}){
      commit('CLEAR_ALL')
    }
  },
  getters:{
    flatenRoutes: state =>{
      return state.flatenRoutes
    },
    routes: state => {
      return state.routes
    },
    loop:state =>{
      return state.loopGuard
    },
    loaded: state => {
      return state.isAddRoutes
    }
  }
})
