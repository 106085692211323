import request from '@/utils/request'
import store from '@/store'

const UserService ={
  login,
  logout,
  fetchNav,
  changePassword
}

function login(username, password, year){
  return request({
    url: '/auth/signin',
    method: 'post',
    data: {
      'username': username,
      'password': password,
      'tahun': year
    }
  }).then(
    data => {
      console.log('login successful')
      return data
    }
  )
}


function logout(){
  console.log('logout ...')
  return request({
    url:'/auth/signout/',
    method:'post',
  })
}


function fetchNav(role){
  return request({
    url:'/menu/'+role+"/",
    method: 'post'
  }).then(
    data => {
      return data
    }
  )
}

function changePassword(data){
  var current = store.state.user.data
  return request({
    url:'/auth/chgpwd',
    method: 'post',
    data: {
      username : current.username,
      oldpassword : data.old_password,
      newpassword : data.new_password,
      newpassword2 : data.confirm_new_password
     }
  })
}

export default UserService