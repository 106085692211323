import store from '@/store'
import {local} from '@/store/local'

const _g = {
  getTriwulan,
  overrideWithPLT,
  isPLT,
  getBaseURLAPIERK,
  listHari,
  listBulan,
  getFullDate,
  getFullDateDay,
  getCurrentUsername,
  isAsisten,
  getMbVersion,
  getMbBuild,
  getMbBuildCount
}

function getTriwulan(){
  var date = new Date()
  // var month = date.getMonth() + 1;
  var month = date.getMonth() === 0 ? 12 : date.getMonth();
  var triwulan = (Math.ceil(month / 3))
  return triwulan.toString()
}

function overrideWithPLT(role){
  if(role.includes("PLT") || role.includes("Kepala UPT Puskesmas") || role.includes("PLH")){
    let user = store.state.user
    var plt_id = getCurrentPLT(role)
    if(plt_id == null){
      // plt_id = user.data.data_asn.plt_id ? JSON.parse(user.data.data_asn.plt_id) : JSON.parse(user.dataPegawai.plt_id)
      plt_id = JSON.parse(user.dataPegawai.plt_id)[0]
    }
  }
  return plt_id
}

function getCurrentPLT(role){
  var plt_name = local.getLocal("plt_names")
  var plt_id = local.getLocal("plt_ids")
  let user = store.state.user
  console.log("get_current_plt_user",user)
  var temp = []
  if(plt_name != null){
    plt_name.forEach(el => {
      var data = el.replace(/\s/g, '')
      temp.push(data)
    });
    var plt_role = role.slice(4).replace(/\s/g, '')
    var i = temp.indexOf(plt_role, null)
    return plt_id[i]
  }else{
    plt_id = JSON.parse(user.dataPegawai.plt_id)
    console.log("global_user_plt_id",plt_id)
    if(!plt_id[0]){
      plt_id = local.getLocal("plt_id")
      console.log("global_plt_id",plt_id)
    }
    return plt_id[0]
  }
}

function isPLT(){
  // var current = store.state.user.data
  let user = store.state.user
  if(typeof user.current.role !== 'undefined'){
    if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
      return true
    }else{
      return false
    }
  }else{
    return false
  }
}

function getBaseURLAPIERK(year, api){
  var base_api = ''
  if (process.env.VUE_APP_API_URL == 'https://mangbagja.bandung.go.id'){
    if(year){
      if(!api){
        //base_api = 'https://kinerja.bandung.go.id/'+year+'/api/'
        base_api = process.env.VUE_APP_API_URL+'/'+year+'/api/'
      }else{
        //base_api = 'https://kinerja.bandung.go.id/'+year+'/api/'+api
        base_api = process.env.VUE_APP_API_URL+'/'+year+'/api/'+api
      }
    }
    // if(year == 2022){
    //   if(process.env.VUE_APP_API_ERK_PROD_2022) {
    //     base_api = process.env.VUE_APP_API_ERK_PROD_2022
    //   }else{
    //     base_api = 'https://kinerja.bandung.go.id/2022/api/'
    //   }
    // }
    // if(year == 2021){
    //   if(process.env.VUE_APP_API_ERK_PROD_2021) {
    //     base_api = process.env.VUE_APP_API_ERK_PROD_2021
    //   }else{
    //     base_api = 'https://kinerja.bandung.go.id/2021/api/'
    //   }
    // }
  }else{
    if (process.env.VUE_APP_API_URL == 'https://mbdemo.rev.web.id'){
      if(year){
         if(!api){
            //base_api = 'https://erk2-demo.rev.web.id/'+year+'/api/'
            base_api = process.env.VUE_APP_API_URL+'/'+year+'/api/'
         }else{
            //base_api = 'https://erk2-demo.rev.web.id/'+year+'/api/'+api
            base_api = process.env.VUE_APP_API_URL+'/'+year+'/api/'+api
         }
      }
    } else {
      if(year){
         if(!api){
            //base_api = 'https://dev-erk.rev.web.id/'+year+'/api/'
            base_api = process.env.VUE_APP_API_URL+'/'+year+'/api/'
         }else{
            //base_api = 'https://dev-erk.rev.web.id/'+year+'/api/'+api
            base_api = process.env.VUE_APP_API_URL+'/'+year+'/api/'+api
         }
      }
    }
    // if(year == 2022){
    //   if(process.env.VUE_APP_API_ERK_DEMO_2022) {
    //     base_api = process.env.VUE_APP_API_ERK_DEMO_2022
    //   }else{
    //     base_api = 'https://demo-erk.rev.web.id/2022/api/'
    //   }
    // }
    // if(year == 2021){
    //   if(process.env.VUE_APP_API_ERK_DEMO_2021) {
    //     base_api = process.env.VUE_APP_API_ERK_DEMO_2021
    //   }else{
    //     base_api = 'https://demo-erk.rev.web.id/2021/api/'
    //   }
    // }
  }
  return base_api
}

function listHari() {
  return ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
}

function listBulan() {
  return ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember']
}

function getFullDate(d){
  let date = d.split("-")
  return parseInt(date[2]) + ' ' + this.listBulan()[(parseInt(date[1])-1)] + ' ' + date[0]
}

function getFullDateDay(d){
  let date = d.split("-")
  let t = new Date(d+'T00:00:00')
  let day = t.getDay()
  return this.listHari()[parseInt(day)] + ', ' + parseInt(date[2]) + ' ' + this.listBulan()[(parseInt(date[1])-1)] + ' ' + date[0]
}

function getCurrentUsername(){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return current.username
}

function isAsisten(){
  let user = store.state.user
  if(user.current.role.includes("jpt") && user.dataPegawai.jabatan.includes("Asisten")){
    return true
  }else{
    return false
  }
}

function getMbVersion(){
   return 'Mangbagja v1.3'
}

function getMbBuild() {
   return 'Lrvl/240525'
}

function getMbBuildCount() {
   return '#643'
}

export default _g