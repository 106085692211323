// kumpulan variable dan functions reusable
import _ from 'lodash'

let months = ['Januari','Febuari' ,'Maret' ,'April' ,'Mei' ,'Juni' ,'Juli' ,'Agustus' ,'September' ,'Oktober' ,'November' ,'Desember']
let defaultRules = {
  required:v=> !!v || v == 0 || 'Tidak boleh kosong',
  harus18:v=> v.length == 18 || 'NIP memerlukan 18 karakter',
  onlyInt:v=> !isNaN(v) || 'Hanya angka',
  min100: v=> (v && v.length > 100) || 'Minimal 100 karakter',
  min25: v=> (v && v.length > 25) || 'Minimal 25 karakter',
  min200: v=> (v && v.length >= 200) || 'Minimal 200 karakter',
  min30: v=> (v && v.length > 30) || 'Minimal 30 karakter',
  min(min, v) {
    if(v >= min){
      return true
    }else{
      return `Nilai must be at least ${min}`
    }
    // return (v || '').length >= min || `Nilai must be at least ${min}`
  },
  max(max, v) { 
    if(v <= max){
      return true
    }else{
      return `Nilai may not be greater than ${max}`
    }
    // return (v || '').length <= max || `Nilai may not be greater than ${max}`
  },
  allowed() {return true}
}

function obj2Arr(items){
  let results = []
  _.forOwn(items, (val,key)=>{
    results.push({id:key, text:val})
  })
  return results
}


function parseBoolean(val){
  if (isNaN(val)){
    if (val == "true"){
      return true
    } else if (val == "false"){
      return false
    }
    return val
  } else {
    return val*1
  }
}



export {months, defaultRules, obj2Arr, parseBoolean}
