import router from '@/router'
import {resetRouter} from '@/router'
import store from '@/store'

const whitelist = ["/login","/404","/"]

router.beforeEach(async(to, from, next)=>{
  let path = to.redirectedFrom || to.path
  let loopGuard = store.state.routes.loopGuard
  let savedRoutes = store.getters['routes/routes']
  let savedFlatenRoutes = store.getters['routes/flatenRoutes']

  // console.log(loopGuard, 'path: ',path,to.redirectedFrom, to.path)
  // reset routes loaded on reload
  if (to.redirectedFrom && to.path == "/404"){
    store.commit('routes/SET_LOADED', false)
    store.commit('routes/RESET_LOOP_GUARD')
    // uncomment to refresh navigation generation
    let current = store.getters['user/current']
    savedRoutes = await store.dispatch('routes/generateRoutes')

    // refresh routes
    // savedRoutes = store.getters['routes/routes']
    // using flaten routes
    savedFlatenRoutes = store.getters['routes/flatenRoutes']
  }

  // uncomment untuk memaksakan routes
  // savedRoutes = await store.dispatch('routes/generateRoutes')
  // resetRouter()
  // let routePath = router.resolve(path)

  //Handle redirect SSO
  // const queryString = window.location.href;
  // const urlParams = new URL(queryString)
  // // const ott = urlParams.searchParams.get('ott')
  // const trimUrlParams = urlParams.hash.slice(2)
  // const myArrayOTT = trimUrlParams.split("=")
  // const ott = myArrayOTT[1]

  // go to path only if it exist in saved routes
 if (!store.getters['routes/loaded'] && savedFlatenRoutes.length > 0 &&     savedFlatenRoutes.find(x => x == path)){
    console.log('permission call generator')

    // make sure routes is added
    console.log('call reset router')
    resetRouter()
    // await router.addRoutes(savedRoutes)
    store.commit('routes/SET_LOADED', true)

    // reset loop before accesing
    store.commit('routes/LOOPING')
    next(path)

  } else if(path === "/login" && store.state.user.data.token){
    // jika user sudah ada token berarti sudah login
    // kembali ke pemilihan peran
    next('/ganti-peran')
  } 
  // else if(path === "/ssoredirect" && ott != null){
  //   // savedRoutes = await store.dispatch('routes/generateRoutes')
  //   // savedFlatenRoutes = store.getters['routes/flatenRoutes']
  //   // store.commit('routes/SET_LOADED', true)
  //   // reset loop before accesing
  //   // store.commit('routes/LOOPING')
  //   next()
  // } 
  else {
    next()
  }

})
