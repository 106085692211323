// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {makeServer} from '../dummyserver/server'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './permission'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import '@/sass/scrollbar.scss'
import paddingNumberClock from './plugins/padding-number-clock.js'
import formatCurrency from './plugins/format-currency.js'

Vue.config.productionTip = false

console.log(process.env.VUE_APP_API_URL)
console.log(process.env.VUE_APP_SERVER)

if(process.env.NODE_ENV === "development" && process.env.VUE_APP_SERVER === "local"){
  makeServer()
}

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
  paddingNumberClock,
  formatCurrency
}).$mount('#app')
