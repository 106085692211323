import UserService from "@/services/UserService";
import DashboardService from "@/services/DashboardService";
import router from "@/router";
import { local } from "@/store/local";
import store from "@/store";
// import store from '@/store'
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    current: {}, // data yang sering diakses; tahun, username dan role
    data: {}, // data user seperti token dll
    err: {}, // error ketika login
    savedUsername: "", // penyimpanan untuk username login
    avatar: "", // foto yang dipakai
    dataPegawai: {}, // data lengkap pegawai
    notifikasi: [],
    lastLogout: null,
    ongoing: false, // memastikan agar tidak tumpuk logout, ongoing logout
    direct: false,
    direct_sso: false,
    dataDiri: {},
  },
  mutations: {
    // LOGIN_SUCCESS(state, {username, year, role, data}){
    LOGIN_SUCCESS(state, data) {
      console.log("login success");
      state.current = {
        username: data.username,
        year: data.tahun,
        role: data.roles[0],
      };
      state.data = _.clone(data);
      state.err = {};
      //get data pegawai
      DashboardService.pegawai().then((response) => {
        let pegawai = response.data.pegawai[0];
        // local.setLocal("plt_id", response.data.pegawai[0].plt_id)
        local.setLocal("kolok", response.data.pegawai[0].kolok);
        var plt_name = JSON.parse(pegawai.plt_name);
        local.setLocal("plt_names", plt_name);
        var plt_id = JSON.parse(pegawai.plt_id);
        local.setLocal("plt_ids", plt_id);
        let user = store.state.user;
        if (
          user.current.role.includes("PLT") ||
          user.current.role.includes("Kepala UPT Puskesmas")
        ) {
          pegawai.jabatan = user.current.role;
          pegawai.jabatan_penyetaraan = "-";
          pegawai.perangkat_daerah = "Pemerintah Kota Bandung";
          pegawai["perangkat daerah"] = "Pemerintah Kota Bandung";
          pegawai.nama = user.current.role;
          pegawai.nip = user.current.username;
        } else {
          user.current.username = pegawai.nip;
        }
        state.dataPegawai = pegawai;

        if (pegawai.foto.match("undefined")) {
          this.avatarPhoto =
            process.env.VUE_APP_IMG_URL + pegawai.nip + ".jpeg";
        } else {
          this.avatarPhoto = pegawai.foto.replace(/\.jpg$/gi, ".jpeg");
        }
        local.setLocal("dataASN", pegawai);
        local.setLocal("avatarPhoto", this.avatarPhoto);
        store.commit("user/SET_AVATAR", this.avatarPhoto);
        store.commit("DATA_PEGAWAI", _.clone(pegawai));
      });
      store.commit("settings/SET_SITE", data.settings);
      setTimeout(function () {
        router.push("/ganti-peran");
      }, 1000);
    },
    LOGIN_FAIL(state, err) {
      state.current = {};
      state.data = {};
      state.err = _.clone(err);
    },
    LOGOUT(state) {
      // let check = store.state.user
      // if(check.data.direct){
      var check_login_ga = local.getLocal("login_via_ga");
      if (state.data.direct || check_login_ga == true) {
        state.current = {};
        state.data = {};
        state.err = {};
        state.dataPegawai = {};
        store.dispatch("routes/reset");
        window.localStorage.clear();
        // state.ongoing= false
        // setTimeout(function(){
        // redirect to gercep-asik
        window.location.href = "https://gercep-asik.bandung.go.id/";
        // router.push('/sso')
        // },5000)
      } else {
        let currentTime = new Date().getTime();
        state.current = {};
        state.data = {};
        state.err = {};
        state.dataPegawai = {};
        store.dispatch("routes/reset");
        window.localStorage.clear();
        // state.ongoing= false
        console.log("ongoing", state.ongoing);
        if (!state.ongoing || currentTime - state.lastLogout > 10000) {
          state.ongoing = true;
          state.lastLogout = new Date().getTime();
          router.push("/logout");
        }
      }
    },
    SET_TOKEN(state, token) {
      state.data.token = token;
    },
    SET_NOTIFIKASI(state, notifikasi) {
      state.notifikasi = notifikasi;
    },
    SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLE(state, role) {
      state.current.role = role;
    },
    CLEAR_ERROR(state) {
      state.err = {};
    },
    SET_ONGOING(state, val) {
      state.ongoing = val;
    },
    SAVE_USERNAME(state, username) {
      state.savedUsername = username;
    },
    DATA_PEGAWAI(state, data) {
      state.dataPegawai = _.clone(data);
    },
    SET_DIRECT(state, direct) {
      state.direct = direct;
    },
    SET_UNAME(state, username) {
      state.data.username = username;
    },
    SET_TAHUN(state, tahun) {
      state.data.tahun = tahun;
    },
    SET_CURRENT(state, current) {
      state.current = current;
    },
  },
  actions: {
    async login({ commit }, { username, password, year }) {
      // login
      let userData = await new Promise((resolve) => {
        UserService.login(username, password, year)
          .then((response) => {
            let data = response.data;
            // cek sudah ada di local storage atau belum
            let userData = _.clone(local.getLocal(username));
            if (
              _.isEmpty(userData) ||
              process.env.VUE_APP_DISABLE_LOCAL_STORAGE
            ) {
              userData = data;
              local.setLocal(username, data);
            }
            resolve(userData);
          })
          .catch((e) => {
            console.error("error=>", e);
            try {
              resolve(e.response.data);
            } catch (e) {
              resolve(e.response);
            }
          });
      });

      console.log("user data =>", userData);
      if (!userData) {
        commit("LOGIN_FAIL", { message: "Sorry, server overload error." });
      } else if (userData.status == 200) {
        console.log(userData);
        commit("LOGIN_SUCCESS", userData);
      } else {
        commit("LOGIN_FAIL", userData);
      }
    },

    logout({ commit }) {
      // panggil service logout
      UserService.logout();
      // delay
      setTimeout(() => {
        commit("LOGOUT");
      }, 1000);
    },

    eject({ commit }) {
      // keluarkan user dari aplikasi tanpa panggil logout ke server
      commit("LOGOUT");
    },

    getDataPegawai({ commit }) {
      return new Promise((resolve) => {
        DashboardService.pegawai().then((response) => {
          let pegawai = response.data.pegawai[0];
          let user = store.state.user;
          if (
            user.current.role.includes("PLT") ||
            user.current.role.includes("Kepala UPT Puskesmas")
          ) {
            pegawai.jabatan = user.current.role;
            pegawai.jabatan_penyetaraan = "-";
            pegawai.perangkat_daerah = "Pemerintah Kota Bandung";
            pegawai["perangkat daerah"] = "Pemerintah Kota Bandung";
            pegawai.nama = user.current.role;
          }
          commit("DATA_PEGAWAI", _.clone(pegawai));
          resolve(pegawai);
        });
      });
    },
  },
  getters: {
    current: (state) => {
      return state.current;
    },
    data: (state) => {
      return state.data;
    },
    token: (state) => {
      try {
        return state.data.token;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    refreshToken: (state) => {
      try {
        return state.data.tokenRefresh;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
  },
};
