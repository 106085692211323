import Vue from 'vue'
import VueRouter from 'vue-router'
import Default from '@/layout/Default'
import store from '@/store'
import _ from 'lodash'

Vue.use(VueRouter)

/** contoh routes:
 *   {
 *       path: '/', // path url
 *       component: Default, // default component
 *       hidden: true, // sembunyikan di menu
 *       meta:{title:'Dashboard', icon:'mdi-view-dashboard'},
 *       children: [{
 *           name:'Dashboard',
 *           path: '',
 *           component: () => import('@/views/dashboard/Dashboard'),
 *         }]
 *     }
 **/

var combinedRoutes = []

const docsRoutes = {
    path:'/docs/',
    component:Default,
    meta:{'icon':'mdi-help','title': 'Not Dashboard'},
    children:[{
      name:'notDashboard',
      path:'/docs/notdashboard',
      component:() => import('@/views/docs/notDashboard')
    },
    {
      name:'docs buttons',
      path:'/docs/buttons',
      component:() => import('@/views/docs/component/Buttons')
    },
    {
      name:'docs grid',
      path:'/docs/grid',
      component:() => import('@/views/docs/component/Grid')
    },
    {
      name:'docs Icons',
      path:'/docs/icons',
      component:() => import('@/views/docs/component/Icons')
    },
    {
      name:'docs Notifications',
      path:'/docs/tabs',
      component:() => import('@/views/docs/component/Tabs')
    },
    {
      name:'docs Typography',
      path:'/docs/typography',
      component:() => import('@/views/docs/component/Typography')
    },
    {
      name:'docs maps',
      path:'/docs/maps',
      component:() => import('@/views/docs/maps/GoogleMaps')
    },
    {
      name:'docs timeline',
      path:'/docs/timeline',
      component:() => import('@/views/docs/pages/Timeline')
    },
    {
      name:'docs user profile',
      path:'/docs/user-profile',
      component:() => import('@/views/docs/pages/UserProfile')
    },
    {
      name:'docs tables',
      path:'/docs/tables',
      component:() => import('@/views/docs/tables/RegularTables')
    }
  ]
}

// routes yang tidak dipengaruhi oleh perubahan role
var constantRoutes = [
  {
    name:'news',
    path:'/',
    hidden:true,
    component:() => import ('@/views/news/News')
  },{
    name:'switch',
    path:'/ganti-peran',
    hidden:true,
    component:() => import ('@/views/SwitchRole')
  },
  {
    name:'Loading',
    path:'/loading',
    hidden:true,
    component:() => import ('@/views/Loading')
  },
  {
    name:"Login",
    path: '/login',
    hidden: true,
    component: () => import('@/views/Login'),
  },{
    name:"Logout",
    path: '/logout',
    hidden: true,
    component: () => import('@/views/Logout'),
  },{
    name:'Ganti Password',
    path:'/ganti-password',
    hidden:true,
    component:() => import ('@/views/ChangePassword')
  },{
    name:'Redirect',
    path:'/sso',
    hidden:true,
    component:() => import ('@/views/ssoredirect')
  },

  // hidden menu detail
  {
    path:'',
    component:Default,
    hidden:true,
    children:[
      {
        name:'Daftar Target Kerja',
        path:'/skp/detail-target-kerja',
        meta:{'title':'Daftar Target Kerja'},
        component:() => import('@/views/skp/PenunjukanKerjaDetail')
      },
    ]
  },

  // end point, jika tidak mendapatkan route
  {
    name:'404',
    path:'/404',
    hidden:true,
    component:() => import('@/views/404')
  },{
    path:'*',
    hidden:true,
    redirect:"/404"
  }
]
console.log('VUE_APP_ENABLE_DOCS', process.env.VUE_APP_ENABLE_DOCS)
if (process.env.VUE_APP_ENABLE_DOCS === 'true'){
  constantRoutes.splice(0,0,docsRoutes)
}

export {constantRoutes}


const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: combinedRoutes.length > 0 ? combinedRoutes:constantRoutes
})

const router = createRouter()
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  combinedRoutes = _.clone(constantRoutes)
  combinedRoutes.splice(combinedRoutes.length - 4,0,...store.getters['routes/routes'])

  const newRouter = createRouter()

  router.matcher = newRouter.matcher // reset router
  // router.options.routes = [] //seems fix duplicate routes bug
}

// export function setRoutes(newRoutes){
//   routes = newRoutes
// }

export default router
