import request from '@/utils/request'

const SSOService ={
  verifyOTT
}

// pengecekan token ott di SSO dan mengembalikan token dari backend MangBagja
function verifyOTT(ott, tahun){
  return request({
    url:'/sso/auth/verify',
    method:'post',
    data:{
      ott:ott,
      tahun:tahun
    }
  })
}

export default SSOService