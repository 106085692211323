<!-- ./components/Snackbar.vue -->
<template>
  <v-snackbar v-model="show" :top="top" :color="color" :centered="centered">
    <v-icon v-if="color==='error'">mdi-alert-octagon</v-icon>
    <v-icon v-else-if="color==='success'">mdi-circle-check</v-icon>
    <v-icon v-else-if="color==='warning'">mdi-alert</v-icon>
    <v-icon v-else-if="color==='info'">mdi-information</v-icon>

    <span class="mx-2">{{message}}</span>

    <template v-slot:action="{attrs}">
      <v-btn small fab icon text color="accent" v-bind="attrs" @click.native="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import store from '@/store'

  export default {
    data() {
      return {
        show: false,
        top: true,
        message: "",
        color: "",
        timeout: 10000,
        centered: ""
      };
    },
    created: function() {
      store.watch(
        state => state.snackbar.snack,
        () => {
          const message = store.state.snackbar.snack.message;
          if (message) {
            this.show = true;
            this.message = message;
            this.color = store.state.snackbar.snack.color;
            if(store.state.snackbar.snack.centered){
              this.centered = store.state.snackbar.snack.centered;
              this.top = false
            }else{
              this.top = true
            }
            setTimeout(
              ()=>{
                store.commit("snackbar/setSnack", {});
              }, 500
            )
          }
        }
      );
    }
  };
</script>
