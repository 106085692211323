import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'
import {local} from '@/store/local'

const DashboardService = {
  notifikasi,
  skpTriwulan,
  pegawai,
  jamKehadiran,
  rataKehadiran,
  capaianKinerja,
  aktifitas,
  getGaji,
  storeSurveyEselon3,
  pegawai2,
  storeSurvey
}


function getGaji(){
  let current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/get-data-gaji',
    method:'post',
    data:{
      nip:current.username
    }
  })
}

function notifikasi(){
  let current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/get-notifikasi',
    method:'post',
    data:{
      nip:current.username,
      tahun: current.year
    }
  })
}

// function notifikasi(){
//   let current = store.state.user.data
//   return request({
//     url:'/page1/notifikasi',
//     method:'post',
//     data:{
//       nip:current.username,
//       tahun:current.tahun,
//       bulan:bulan
//     }
//   })
// }

function skpTriwulan(){
  let current = store.state.user.data
  var bulan = _g.getTriwulan
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'/page1/skp_triwulan',
    method:'post',
    data:{
      nip:current.username,
      tahun:current.tahun,
      bulan:bulan
    }
  })
}


function pegawai(){
  let userData = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    if(user.dataPegawai.plt_id == undefined){
      let local_nip = local.getLocal("nip")
      // userData.username = local_nip ? local_nip : user.current.username
      userData.username = local_nip ? local_nip : user.data.username
    }else{
      userData.username = _g.overrideWithPLT(user.current.role)
    }
  }else{
    let local_nip = local.getLocal("nip")
    // userData.username = local_nip ? local_nip : user.current.username
    userData.username = local_nip ? local_nip : user.data.username
  }
  // let username = nip ? nip : userData.username
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page1/pegawai',
    method:'post',
    data:{
      nip:userData.username,
      tahun:userData.tahun,
      bulan:(bulan+1)
    }
  })
}

function jamKehadiran(){
  let userData = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    userData.username = _g.overrideWithPLT(user.current.role)
  }else{
    userData.username = user.current.username
  }
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page1/jam_kehadiran',
    method:'post',
    data:{
      nip:userData.username,
      tahun:userData.tahun,
      bulan:bulan
    }
  })
}

function rataKehadiran(){
  let userData = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    userData.username = _g.overrideWithPLT(user.current.role)
  }else{
    userData.username = user.current.username
  }
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page1/rata_rata_kehadiran',
    method:'post',
    data:{
      nip:userData.username,
      tahun:userData.tahun,
      bulan:bulan
    }
  })
}

function capaianKinerja(){
  let userData = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    userData.username = _g.overrideWithPLT(user.current.role)
  }else{
    userData.username = user.current.username
  }
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page1/capaian_kinerja_bulan_lalu',
    method:'post',
    data:{
      nip:userData.username,
      tahun:userData.tahun,
      bulan:bulan
    }
  })
}

function aktifitas(){
  let userData = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    userData.username = _g.overrideWithPLT(user.current.role)
  }else{
    userData.username = user.current.username
  }
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page1/aktifitas',
    method:'post',
    data:{
      nip:userData.username,
      tahun:userData.tahun,
      bulan:bulan
    }
  })
}

function storeSurveyEselon3(data){
  let userData = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    userData.username = _g.overrideWithPLT(user.current.role)
  }else{
    userData.username = user.current.username
  }
  return request({
    url:'/store-survey',
    method:'post',
    data:{
      nip:userData.username,
      ...data
    }
  })
}

function pegawai2(tahun, token){
  var date = new Date()
  var bulan = date.getMonth()
  let user = store.state.user
  let current = store.state.user.data
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    if(user.current.username.length != 18){
      current.username = local.getLocal("nip") != null ? local.getLocal("nip") : user.data.id
      token = local.getLocal("nip_token") != null ? local.getLocal("nip_token") : token
    }
  }
  return request({
    url:'/page1/pegawai',
    method:'post',
    data:{
      nip:current.username,
      tahun:tahun,
      bulan:(bulan+1),
      token:token
    }
  })
}

function storeSurvey(data){
  let userData = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    userData.username = _g.overrideWithPLT(user.current.role)
  }else{
    userData.username = user.current.username
  }
  return request({
    url:'/store-survey',
    method:'post',
    data:{
      nip:userData.username,
      ...data
    }
  })
}

export default DashboardService
