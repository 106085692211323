import Vue from 'vue'

const paddingNumberClock = Vue.filter('paddingNumberClock', function (value) {
    if(value < 10){
        return "0"+value;
    }else{
        return value;
    }
});

export default paddingNumberClock